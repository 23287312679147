.leftSidebarMap .gm-style-iw.gm-style-iw-c {
  padding: 0px;
  margin: 0px;
}

.leftSidebarMap .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  padding: 0px;
  margin: 0px;
  overflow: hidden !important;
}

.mapContentSection .markerHeading {
  font-size: 12px;
  margin: 0px 0 4px !important;
  padding: 0px !important;
}

.mapContentSection {
  min-width: 280px;
  padding: 15px;
}

.leftBottomMarker {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-flow: row-reverse;
}

.leftBottomMarker svg {
  max-width: 20px;
  max-height: 20px;
}
a.viewOnmap{
  font-weight: 500;
  text-decoration: none !important;
}

.leftSidebarMap .gm-style-iw-chr {
  justify-content: space-between;
  flex-direction: row-reverse;
  position: absolute;
  z-index: 999;
  color: #ffff;
}

.leftSidebarMap .gm-style-iw-chr button.gm-ui-hover-effect {
  background: #fff !important;
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
}

.leftSidebarMap .gm-style-iw-chr button.gm-ui-hover-effect span {
  border-radius: 50%;
  padding: 0px;
  height: 20px !important;
  width: 20px !important;
  margin: 0px !important;
}

.scrollingTabsContainer a.headerLoginBtn {
  background: #f5a688;
  color: #000;
  padding: 12px 40px;
  border-radius: 40px;
}

a.leftSideBarLoginBtn {
  border-radius: 40px;
  background: #f5a688;
  color: #000;
  width: 100%;
  min-width: 150px;
  display: flex;
  justify-content: center;
  padding: 10px 12px 11px;
  margin-bottom: 10px;
}
p.formErrorMsg {
  color: #f5a688;
}
.leftSidebarMap > div:not(.modal-overlay) {
  position: relative;
}
.mapLocationIcon {
  position: absolute;
  bottom: 116px;
  right: 12px;
  width: 40px;
}
.mapLocationIcon svg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.calendarDateDropdown {
  position: absolute;
  top: 46px;
}
.closeMobileMapIcon {
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 999999;
}

.closeMobileMapIcon svg {
  width: 30px;
  height: 30px;
}

.smallLoaderWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  text-align: center;
  background: rgb(153 153 153 / 41%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  z-index: 999;
}

.acess_rec_data.acess_rec_data_mobile.desktop {
  background: #fff;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-inputs {
  display: flex;
  gap: 10px; /* Space between input fields */
}

.search-bar {
  width: 30%; /* Adjust width to fit three inputs side by side */
  padding: 8px;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.dropdown {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 5px;
}

.dropdown-item {
  padding: 5px 0;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
.travelers-container {
  padding: 20px;
}

.travelers-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.traveler-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  text-align: center;
}

.traveler-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.traveler-info {
  margin-top: 10px;
}

.traveler-info h3 {
  font-size: 16px;
  margin: 0;
}

.traveler-info p {
  margin: 5px 0;
} 
.blogs-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.blog-image {
  width: 100%;
  height: auto;
}

.blog-content {
  padding: 15px;
}

.blog-title {
  font-size: 1.2rem;
  margin: 0;
}

.blog-date {
  font-size: 0.9rem;
  color: #777;
  margin: 5px 0;
}

.blog-category {
  font-size: 0.9rem;
  color: #007bff;
  margin: 5px 0;
}

.category-item {
  display: inline-block;
  margin-right: 5px;
}

.blog-excerpt {
  color: #555;
  margin: 10px 0;
}

.blog-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}
.userProfileModal {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: #fff;
  padding: 12px 6px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 9 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
button.gm-control-active.gm-fullscreen-control {
  display: none;
}
.userProfileModal:hover{
  animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;

}
.userProfileModal::after{
  content: '';
  height: 0;
  position: absolute;
  top: -24px;
  width: 0;
  right: auto;
  left: 12px;
  border-left: 14px solid #ffffff;
  border-top: 36px solid transparent;
  transform: rotate(271deg);
  border-radius: 0px 0 4px 0;  
}
@keyframes shake{
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
.userProfileModal:before {
  content: '';
  height: 0;
  position: absolute;
  top: -9px;
  width: 0;
  right: auto;
  left: 9px;
  border-right: 26px solid #333333;
  border-top: 9px solid transparent;
  z-index: 3;
  border-radius: 0;
}
.userProfileModal img {
  position: relative;
  max-width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: contain;
}
.totalRecommendations {
  background: #f5a688;
  padding: 10px 12px;
  border-radius: 50%;
}
span.listingBadge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #000;
  color: #fff;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 12px;
}
li.d-flex.align-items-center.countryRegion {
  text-decoration: underline;
}
li.d-flex.align-items-center.countryRegion.clicked {
  color: #f5a688;
}