.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(77, 77, 77, .7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 122;
  padding: 30px 0;
  overflow: auto;
}

/* width */
.modal-overlay::-webkit-scrollbar {width: 18px;}
.modal-overlay::-webkit-scrollbar-track {}
.modal-overlay::-webkit-scrollbar-thumb {}
.modal-overlay::-webkit-scrollbar-thumb:hover {}

.modal-contents {
  position: relative;
  margin: auto;
  text-align: right;
  background-color: #F0ECEC;
  box-shadow: none;
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  padding: 0;
}
.modal-contents .modal_body {
  padding: 40px 55px;
}
.modal-contents .modal_body h2 {
  font-size: 20px;
  margin: 10px 0 1.5rem;
  font-weight: 600;
}
.modal-overlay .modal-contents .modal_body {
  overflow: hidden;
  max-height: initial;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.submit-button {
  width: 10%; /* Adjust as needed */
}
.modal-contents span.close {
  height: 24px;
  width: 24px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 20px;
  right: 20px;
}
.modal-field {
  margin-bottom: 15px;
}
.modal-field label {
  margin: 0 0 10px;
  display: block;
  position: relative;
}
.modal-field input, .modal-field select {
  width: 100%;
  border: 0;
  line-height: 22px;
  height: 42px;
  padding: 10px;
  border-radius: 6px;
  border: 0;
  outline: none;
  font-size: 14px;
}
.selectfield{
  position: relative;
}
.selectfield::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  content: "\F107";
  font-family: Font Awesome\ 5 Free;
  display: block;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  pointer-events: none;
}
.selectfield select {
  appearance: none;
  color: #000;
}
.dateInput .p-inputwrapper {
  display: block;
}
.dateInput .cal_icon {
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  pointer-events: none;
}
.dateInput .cal_icon svg {
  margin: 0;
  width: 24px;
  height: 24px;
}
.p-datepicker.p-component .p-datepicker-header {
  direction: ltr;
}
.p-datepicker.p-component .p-datepicker-header .p-datepicker-title button {
  margin-top: 0;
}
.p-datepicker.p-component .p-datepicker-header .p-datepicker-title button {
  margin-top: 0;
  background: transparent;
  padding: 0;
  color: #000;
  font-size: 14px;
  min-height: auto;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
  line-height: inherit;
  background: #333;
  padding: 4px;
  font-size: 10px;
  height: 20px;
  width: 20px;
  margin: 0;
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext{
  box-shadow: none;
}

.modal-actions {
  text-align: center;
  margin: 20px 0 20px;
  border-top: 1px solid #ddd;
  padding: 20px 0 0;
}
.save-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #0056b3;
}

.modal-field select:focus {
  outline: none;
  border-color: #007bff;
}

img.disabled {
  pointer-events: none;
  filter: blur(2px);
}

input.disabled {
  pointer-events: none;
  /* filter: blur(2px); */
}

.contactModalOverlay {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactModal {
    position: relative;
    width: 300px; /* Adjust the width as needed */
    max-width: 90%;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

textarea {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
}
/* Overlay styles */
.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal styles */
.contactModal {
  position: relative;
  width: 300px; /* Adjust the width as needed */
  max-width: 90%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

textarea {
  width: 100%;
  height: 100px; /* Adjust height as needed */
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}

button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Adjust color as needed */
  color: white;
  cursor: pointer;
}

button + button {
  background-color: #6c757d; /* Adjust color as needed */
  margin-left: 10px;
}
.podcast-section {
  margin: 2rem 0;
  text-align: center;
}

.podcast-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.podcast-item {
  width: 200px;
  margin: 1rem;
}

.podcast-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.podcast-item h3 {
  margin: 0.5rem 0;
}

.podcast-item a {
  text-decoration: none;
  color: #392D77;
  font-weight: bold;
}
.price-filled {
  color: #f5a688;
}

.price-empty {
  color: lightgray;
}
.truncate-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust the width based on your layout */
  display: inline-block;
}
